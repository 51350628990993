@use "src/_app/styles/mixins.scss" as *;
@font-face {
  font-family: Golos;
  src: url('../../../public/fonts/Golos-Text/Golos-Text_Regular.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Regular'),
    url('../../../public/fonts/Golos-Text/Golos-Text_Regular.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: url('../../../public/fonts/Golos-Text/Golos-Text_Black.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Black'),
    url('../../../public/fonts/Golos-Text/Golos-Text_Black.woff2')
      format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: url('../../../public/fonts/Golos-Text/Golos-Text_Bold.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Bold'),
    url('../../../public/fonts/Golos-Text/Golos-Text_Bold.woff2')
      format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: url('../../../public/fonts/Golos-Text/Golos-Text_DemiBold.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_DemiBold'),
    url('../../../public/fonts/Golos-Text/Golos-Text_DemiBold.woff2')
      format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Golos;
  src: url('../../../public/fonts/Golos-Text/Golos-Text_Medium.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Medium'),
    url('../../../public/fonts/Golos-Text/Golos-Text_Medium.woff2')
      format('woff2');
  font-weight: 500;
  font-style: normal;
}

.container {
  max-width: 1274px;
  margin: 0 auto;
  padding: 0 14px;
}

.main__container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px;

  @include respond(laptop) {
    max-width: 1024px;
    padding: 0 32px;
  }

  @include respond(tabletS) {
    padding: 0 16px;
  }

  @include respond(mobileL) {
    max-width: 425px;
    padding: 0 16px;
  }
}

.swiper-wrapper {
  cursor: grab;
}

.swiper-slide {
  overflow: visible;
  padding: 25px 0;
  height: auto !important;
}

.SnackbarContent-root {
  align-items: flex-start !important;
}

.SnackbarContainer-root {
  max-width: 40% !important;

  @include respond(tablet) {
    max-width: calc(100% - 32px) !important;
  }
}

.SnackbarItem-message {
  max-width: 85%;
  padding: 10px 0 !important;
  align-items: flex-start !important;

  @include respond(laptop) {
    max-width: 80%;
  }
}

/* RTL SUPPORT */

body[dir='rtl'] {
  .SnackbarItem-action {
    padding-left: unset;
    padding-right: 16px;
    margin-left: unset;
    margin-right: auto;
  }

  .SnackbarItem-message {
    svg {
      margin-inline-start: 8px !important;
      margin-inline-end: unset !important;
    }
  }

  .swiper-slide {
    direction: rtl;
  }

  .swiper:not([dir='ltr']) {
    direction: rtl;

    .swiper-slide {
      margin-right: unset !important;
    }
  }

  svg {
    .swiper-slide {
      direction: ltr;
    }
  }
}

/* RTL SUPPORT END */

html,
body,
text {
  padding: 0;
  margin: 0;
  font-family: Golos, sans-serif;

  .MuiButtonBase-root {
    color: white;

    &:hover {
      background-color: var(--btnSecondaryActive);
    }
  }
}

.none {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;

  /* 1 */
}

* {
  box-sizing: border-box;
}

/* Document
   ========================================================================== */

html {
  line-height: 1.15;

  /* 1 */

  /* 2 */
  text-size-adjust: 100%;

  /* 2 */
}

/* Sections
   ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  margin: 0;
  position: static !important;

  // fix for IOS horizontal orientation issues
  background-color: #000;

  &.blog {
    background: #fff;
  }
}

/**
 * Add the correct display in IE 9-.
 */

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
}

/* Grouping content
   ========================================================================== */

/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */

figcaption,
figure,
main {
  /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */

figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box;

  /* 1 */
  height: 0;

  /* 1 */
  overflow: visible;

  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace;

  /* 1 */
  font-size: 1em;

  /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none;

  /* 1 */
  text-decoration: underline dotted;

  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */

// b,
// strong {
//   font-weight: inherit;
// }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace;

  /* 1 */
  font-size: 1em;

  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */

dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */

mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family:
    Golos,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 100%;

  /* 1 */
  line-height: 1.15;

  /* 1 */
  margin: 0;

  /* 2 */
  appearance: none;
  border-radius: 0;
}

select::-ms-expand {
  display: none;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
  border-radius: 0;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type='button'],
/* 1 */
[type='reset'],
[type='submit'] {
  appearance: none;

  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box;

  /* 1 */
  color: inherit;

  /* 2 */
  display: table;

  /* 1 */
  max-width: 100%;

  /* 1 */
  padding: 0;

  /* 3 */
  white-space: normal;

  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  display: inline-block;

  /* 1 */
  vertical-align: baseline;

  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;

  /* 1 */
  padding: 0;

  /* 2 */
}

/* Delete numbers arrows in input type=number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  appearance: textfield;

  /* 1 */
  outline-offset: -2px;

  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  font: inherit;

  /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */

details,
/* 1 */
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
   ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* Hidden
   ========================================================================== */

main {
  // background: #fff;
}

[hidden] {
  display: none;
}

.modal-open {
  // position: fixed;
  // overflow: hidden;
  // width: 100%;
  // height: 100vh;
}

// FONTS END

.blockchains-swiper {
  overflow: visible !important;

  .swiper-button-prev {
    background-image: url('/icons/common/blockchains-arrow-left.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    top: -65%;
    width: 48px !important;
    height: 48px !important;
    left: auto;
    right: 128px;

    &::after {
      display: none;
    }

    @media (width <=1024px) {
      display: none;
    }
  }

  .swiper-button-next {
    background-image: url('/icons/common/blockchains-arrow-right.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    top: -65%;
    width: 48px !important;
    height: 48px !important;
    left: auto;
    right: 56px;

    &::after {
      display: none;
    }

    @media (width <=1024px) {
      display: none;
    }
  }
}

.reviews-swiper {
  overflow: visible !important;

  .swiper-button-prev {
    background-image: url('/icons/common/reviews-arrow-left.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    top: -20%;
    width: 48px !important;
    height: 48px !important;
    left: auto;
    right: 128px;

    &::after {
      display: none;
    }

    @media (width <=1024px) {
      display: none;
    }
  }

  .swiper-button-next {
    background-image: url('/icons/common/reviews-arrow-right.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    top: -20%;
    width: 48px !important;
    height: 48px !important;
    left: auto;
    right: 56px;

    &::after {
      display: none;
    }

    @media (width <=1024px) {
      display: none;
    }
  }
}

.blog-swiper {
  // overflow: visible !important;
  // overflow-y: visible !important;
  // overflow-x: hidden !important;
  // overflow-y: visible !important;
  overflow: visible !important;

  .swiper-slide {
    // max-width: 304px !important;
  }

  .swiper-button-prev {
    background-image: url('/icons/common/reviews-arrow-left.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    top: -13%;
    width: 48px !important;
    height: 48px !important;
    left: auto;
    right: 72px;

    &::after {
      display: none;
    }

    @media (width <=1024px) {
      display: none;
    }
  }

  .swiper-button-next {
    background-image: url('/icons/common/reviews-arrow-right.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    top: -13%;
    width: 48px !important;
    height: 48px !important;
    left: auto;
    right: 0;

    &::after {
      display: none;
    }

    @media (width <=1024px) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  opacity: 0;
}

@font-face {
  font-family: Golos, sans-serif;
  src: url('/fonts/Golos-Text/Golos-Text_Regular.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Regular'),
    url('/fonts/Golos-Text/Golos-Text_Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Golos, sans-serif;
  src: url('/fonts/Golos-Text/Golos-Text_Black.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Black'),
    url('/fonts/Golos-Text/Golos-Text_Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Golos, sans-serif;
  src: url('/fonts/Golos-Text/Golos-Text_Bold.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Bold'),
    url('/fonts/Golos-Text/Golos-Text_Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Golos, sans-serif;
  src: url('/fonts/Golos-Text/Golos-Text_DemiBold.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_DemiBold'),
    url('/fonts/Golos-Text/Golos-Text_DemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Golos, sans-serif;
  src: url('/fonts/Golos-Text/Golos-Text_Medium.woff');
  src:
    local('Golos-Text'),
    local('Golos-Text_Medium'),
    url('/fonts/Golos-Text/Golos-Text_Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
