@use "src/_app/styles/mixins.scss" as *;
.container {
  width: fit-content;
  height: fit-content;
  max-width: calc(100% - 48px);
  margin-bottom: 16px;

  @include respond(tablet) {
    margin-inline-end: 16px;
  }

  @include respond(tabletS) {
    top: 20px;
    right: env(safe-area-inset-right);
    left: initial;
  }

  @include respond(mobileL) {
    max-width: calc(100% - 32px);
  }

  @include respond(tabletS) {
    @include rtl {
      right: initial;
      left: calc(env(safe-area-inset-right));
    }
  }

  &.dark {
    :global {
      .Toastify__toast {
        box-shadow: 4px 4px 16px 6px rgb(0 0 0 / 28%);
      }
    }
  }

  .closeButton {
    color: var(--toggleText);
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  :global {
    .Toastify__toast {
      max-width: 593px;
      width: max-content;
      border-radius: 16px;
      min-height: 56px;
      padding: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      background: var(--dropdown_bg);
      color: var(--text_icon_primary);
      column-gap: 24px;
      box-shadow: 0 8px 58px 0 rgb(0 0 0 / 16%);
      font-family: Golos, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @include respond(tablet) {
        margin-inline-start: 24px;
        max-width: 100%;
      }

      @include respond(tabletS) {
        margin-inline-start: 16px;
      }
    }

    .Toastify__toast-container {
      width: fit-content;
    }

    .Toastify__toast-icon {
      width: 24px;
      height: 24px;
      margin-inline-end: 12px;
      color: var(--text_icon_primary);
    }

    .Toastify__slide-enter--top-right,
    .Toastify__slide-enter--top-left,
    .Toastify__slide-exit--top-right,
    .Toastify__slide-exit--top-left {
      animation-duration: 0.3s !important;
    }
  }
}
